.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  background: rgb(1, 55, 87, 0.4);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8rem;
  transition: all 0.3s ease;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: var(--primary-color);
  border-color: var(--primary-color);
}
.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
