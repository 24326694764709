.loginwrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 150px;
}
.login-area {
  width: 100%;
  position: relative;
}
.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 9px;
}
.logo img {
  max-height: 61px;
}
.login-wrap {
  width: 100%;
  height: 240px;
  background-color: var(--primary-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  position: relative;
}
.loginform {
  position: absolute;
  top: 17px;
  width: 15%;
  letter-spacing: 0.08rem;
  font-size: 8pt;
}
.subarea {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33px 0px;
}
.loginforget {
  padding: 5px 0px;
  margin-top: -10px;
  text-align: right;
  margin-right: 20px;
  color: var(--primary-green);
}
.loginforget:hover {
  cursor: pointer;
}
.copyight {
  width: 100%;
  position: absolute;
  bottom: 3px;
  font-size: 8pt;
  color: var(--primary-green);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1450px) {
  .loginform {
    width: 10%;
  }
}
@media (max-width: 950px) {
  .loginform {
    width: 25%;
  }
}
@media (max-width: 590px) {
  .loginform {
    width: 50%;
  }
}
