.link {
  color: white;
  display: block;
  padding: 7px 0px;

  margin-right: 80px;
}
.link:hover {
  border-bottom: 3px solid white;
}
.link.active {
  border-bottom: 3px solid white;
}

@keyframes glow {
  0% {
    box-shadow: 0px 0px 0px white;
  }
  50% {
    box-shadow: 0px 0px 20px white;
  }
  100% {
    box-shadow: 0px 0px 0px white;
  }
}
@keyframes glow2 {
  from {
    box-shadow: 0px 0px 0px white;
  }
  to {
    box-shadow: 0px 0px 20px white;
  }
}
